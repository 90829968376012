import React, { Fragment } from "react";
import Link from "next/link";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { PageMeta } from "@eleo/components/components/PageMeta/PageMeta";
import { GridContainer } from "@eleo/components/components/Layout/GridContainer";
import { GridItem } from "@eleo/components/components/Layout/GridItem";
import Button from "@eleo/components/components/Button/Button";
import { Caption } from "@eleo/components/components/Typography/Typography";
import { FormGroup } from "@eleo/components/components/FormGroup/FormGroup";
import { styled } from "@eleo/components/styles";

const Logo = styled("img", {
  height: "50px",
  width: "auto",
});

const LogoContainer = styled("div", {
  paddingTop: "$2",
});

const Form = styled("form", {
  display: "flex",
  height: "100vh",
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "500px",
  padding: "$2",
});

const LandingPage = () => {
  const router = useRouter();
  const { register, handleSubmit } = useForm<{ query: string }>();

  function onSubmit({ query }: { query: string }) {
    router.push("/search/[query]", `/search/${query}`);
  }

  return (
    <Fragment>
      <PageMeta title="" />

      <Form onSubmit={handleSubmit(onSubmit)} action="/search/" method="get">
        <GridContainer
          css={{
            marginBottom: "auto",
            marginTop: "auto",
            rowGap: "$4",
            width: "100%",
          }}
        >
          <GridItem centered>
            <Link href={"/"}>
              <LogoContainer>
                <Logo src="/images/logo.svg" alt="Eleo" />
              </LogoContainer>
            </Link>
          </GridItem>

          <GridItem>
            <FormGroup
              label="What are you looking for today?"
              id={"query"}
              type={"search"}
              inputProps={{
                ...register(`query`),
                autoFocus: true,
                required: true,
              }}
            />
          </GridItem>

          <GridItem>
            <Button type="submit">Search</Button>
          </GridItem>

          <GridItem centered>
            <Caption>
              <a href="https://eleo.app?utm_source=eleo-landing-page">
                Create your store 🚀
              </a>
            </Caption>
          </GridItem>
        </GridContainer>
      </Form>
    </Fragment>
  );
};

LandingPage.layoutOptions = {
  hideTopNav: true,
};

export default LandingPage;
